/* eslint-disable react/jsx-curly-newline */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react';
import Fade from '@material-ui/core/Fade';

import { CameraAlt } from '@material-ui/icons';

import searchIconDetails from '../../../../assets/svg/searchIconDetails.svg';
import editActionsIcon from '../../../../assets/svg/editActionsIcon.svg';
import { Table } from '../../../../components/NewTable';
import { IOrdersTableProps, IOrder } from '../../types';
import DetailsItem from './DetailsItem';
import {
  TableFilterCardContainer,
  TableContainer,
  Container,
} from '../../styles/styles';
import TagStatus from '../../../../components/TagStatus';
import pickupIcon from '../../../../assets/svg/pickupIcon.svg';
import sentIcon from '../../../../assets/svg/sentIcon.svg';

const OrdersTable: React.FC<IOrdersTableProps> = ({
  handleFilter,
  orderTableRows,
  openDetail,
  resetOrder,
  openSignature,
}) => {
  const [rows, setRows] = useState<IOrder[]>([]);

  const orderIcon = (orderType: string | null) => {
    switch (orderType) {
      case 'Envio':
        return sentIcon;
      case 'Retira':
        return pickupIcon;
      default:
        return sentIcon;
    }
  };

  const pendingFilter = (array: IOrder[], key: string) => {
    switch (key) {
      case 'Em Separação': {
        const resultsStatus = array.filter(item => {
          return item.status === 'Em Separação';
        });
        return resultsStatus.length;
      }
      case 'Em Trânsito': {
        const resultsStatus = array.filter(item => {
          return item.status === 'Em Trânsito';
        });
        return resultsStatus.length;
      }
      case 'A Separar': {
        const resultsStatus = array.filter(item => {
          return item.status === 'A Separar';
        });
        return resultsStatus.length;
      }
      case 'A Conferir': {
        const resultsStatus = array.filter(item => {
          return item.status === 'A Conferir';
        });
        return resultsStatus.length;
      }
      case 'Em Conferência': {
        const resultsStatus = array.filter(item => {
          return item.status === 'Em Conferência';
        });
        return resultsStatus.length;
      }
      case 'Aguardando Faturamento': {
        const resultsStatus = array.filter(item => {
          return item.status === 'Aguardando Faturamento';
        });
        return resultsStatus.length;
      }
      case 'Preparado': {
        const resultsStatus = array.filter(item => {
          return item.status === 'Preparado';
        });
        return resultsStatus.length;
      }
      case 'Entregue': {
        const resultsStatus = array.filter(item => {
          return item.status === 'Entregue';
        });
        return resultsStatus.length;
      }
      case 'A Armazenar': {
        const resultsStatus = array.filter(item => {
          return item.status === 'A Armazenar';
        });
        return resultsStatus.length;
      }
      case 'Recebido': {
        const resultsStatus = array.filter(item => {
          return item.status === 'Recebido';
        });
        return resultsStatus.length;
      }
      case 'A Cancelar': {
        const resultsStatus = array.filter(item => {
          return item.status === 'A Cancelar';
        });
        return resultsStatus.length;
      }
      case 'Cancelado': {
        const resultsStatus = array.filter(item => {
          return item.status === 'Cancelado';
        });
        return resultsStatus.length;
      }
      default:
        return array.length;
    }
  };

  useEffect(() => {
    setRows(orderTableRows);
  }, [orderTableRows]);

  return (
    <>
      <Fade in timeout={1000} unmountOnExit>
        <Container>
          <TableFilterCardContainer>
            <div>
              <button type="button" onClick={() => handleFilter({})}>
                <DetailsItem
                  card
                  title="Resultados"
                  value={rows.length}
                  id="results"
                />
              </button>
              <button
                type="button"
                onClick={() => handleFilter({ status: 'A Separar' })}
              >
                <DetailsItem
                  card
                  title="A Separar"
                  value={pendingFilter(rows, 'A Separar')}
                  id="pending"
                />
              </button>
              <button
                type="button"
                onClick={() => handleFilter({ status: 'Em Separação' })}
              >
                <DetailsItem
                  card
                  title="Em Separação"
                  value={pendingFilter(rows, 'Em Separação')}
                  id="ongoing"
                />
              </button>
              <button
                type="button"
                onClick={() => handleFilter({ status: 'A Conferir' })}
              >
                <DetailsItem
                  card
                  title="A Conferir"
                  value={pendingFilter(rows, 'A Conferir')}
                  id="waiting"
                />
              </button>
              <button
                type="button"
                onClick={() => handleFilter({ status: 'Em Conferência' })}
              >
                <DetailsItem
                  card
                  title="Em Conferência"
                  value={pendingFilter(rows, 'Em Conferência')}
                  id="ongoing"
                />
              </button>
              <button
                type="button"
                onClick={() => handleFilter({ status: 'Em Trânsito' })}
              >
                <DetailsItem
                  card
                  title="Em Trânsito"
                  value={pendingFilter(rows, 'Em Trânsito')}
                  id="ongoing"
                />
              </button>

              <button
                type="button"
                onClick={() =>
                  handleFilter({ status: 'Aguardando Faturamento' })
                }
              >
                <DetailsItem
                  card
                  title="Aguardando Faturamento"
                  value={pendingFilter(rows, 'Aguardando Faturamento')}
                  id="waiting"
                />
              </button>
              <button
                type="button"
                onClick={() => handleFilter({ status: 'A Armazenar' })}
              >
                <DetailsItem
                  card
                  title="A Armazenar"
                  value={pendingFilter(rows, 'A Armazenar')}
                  id="waiting"
                />
              </button>
              <button
                type="button"
                onClick={() => handleFilter({ status: 'Preparado' })}
              >
                <DetailsItem
                  card
                  title="Preparado"
                  value={pendingFilter(rows, 'Preparado')}
                  id="expeditionCompleted"
                />
              </button>
              <button
                type="button"
                onClick={() => handleFilter({ status: 'Recebido' })}
              >
                <DetailsItem
                  card
                  title="Recebido"
                  value={pendingFilter(rows, 'Recebido')}
                  id="expeditionCompleted"
                />
              </button>
              <button
                type="button"
                onClick={() => handleFilter({ status: 'Entregue' })}
              >
                <DetailsItem
                  card
                  title="Entregue"
                  value={pendingFilter(rows, 'Entregue')}
                  id="done"
                />
              </button>
              <button
                type="button"
                onClick={() => handleFilter({ status: 'A Cancelar' })}
              >
                <DetailsItem
                  card
                  title="A Cancelar"
                  value={pendingFilter(rows, 'A Cancelar')}
                  id="divergent"
                />
              </button>
              <button
                type="button"
                onClick={() => handleFilter({ status: 'Cancelado' })}
              >
                <DetailsItem
                  card
                  title="Cancelado"
                  value={pendingFilter(rows, 'Cancelado')}
                  id="canceled"
                />
              </button>
              {/* TODO: Terminar os filtros */}
            </div>
          </TableFilterCardContainer>
          <TableContainer>
            <Table<IOrder>
              columns={[
                {
                  title: 'Id',
                  type: 'stringCenter',
                  orderable: true,
                  props: ['orderId'],
                  cssProps: {
                    width: '6%',
                  },
                  renderItem: row => (
                    <div className="orderColumn">
                      <img alt="" src={orderIcon(row.typeOrder)} />
                      <p style={{ marginLeft: '10px' }}>{row.orderId}</p>
                    </div>
                  ),
                },
                {
                  title: 'Número do pedido',
                  type: 'string',
                  orderable: false,
                  props: ['orderNumber'],
                },
                {
                  title: 'Loja',
                  type: 'stringCenter',
                  orderable: true,
                  props: ['branchId'],
                },
                {
                  title: 'Cliente',
                  type: 'string',
                  orderable: true,
                  props: ['customerName'],
                  cssProps: {
                    width: '8%',
                  },
                },
                {
                  title: 'Data Pedido',
                  type: 'datetime',
                  orderable: true,
                  props: ['creationDate'],
                },

                {
                  title: 'Qtd',
                  type: 'number',
                  orderable: true,
                  props: ['quantity'],
                  cssProps: {
                    width: '8%',
                  },
                  renderItem: row => (
                    <div className="centerCell">
                      <p>{row.totals.quantity}</p>
                    </div>
                  ),
                },
                {
                  title: 'Qtd. Sep',
                  type: 'number',
                  orderable: true,
                  props: ['checked'],
                  cssProps: {
                    width: '8%',
                  },
                  renderItem: row => (
                    <div className="centerCell">
                      <p>{row.totals.checked}</p>
                    </div>
                  ),
                },
                {
                  title: 'Qtd. Conf',
                  type: 'number',
                  orderable: true,
                  props: ['conferred'],
                  cssProps: {
                    width: '8%',
                  },
                  renderItem: row => (
                    <div className="centerCell">
                      <p>{row.totals.conferred}</p>
                    </div>
                  ),
                },
                {
                  title: 'Excluído',
                  type: 'number',
                  orderable: true,
                  props: ['excluded'],
                  cssProps: {
                    width: '8%',
                  },
                  renderItem: row => (
                    <div className="centerCell">
                      <p>{row.totals.excluded}</p>
                    </div>
                  ),
                },
                {
                  title: 'Status',
                  type: 'stringCenter',
                  orderable: true,
                  props: ['status'],
                  renderItem: row => <TagStatus status={row.status} />,
                },
                {
                  title: 'Ações',
                  type: 'stringCenter',
                  props: ['status'],
                  renderItem: row => (
                    <div className="row-actions-button-detail">
                      <button
                        type="button"
                        className="action"
                        onClick={() => openDetail(row)}
                      >
                        <img
                          src={searchIconDetails}
                          style={{ width: '20px' }}
                          alt="detalhes"
                        />
                        <p className="hover-item">Detalhes</p>
                      </button>

                      {row.status === 'Em Separação' ? (
                        <button
                          type="button"
                          className="action"
                          onClick={() => resetOrder(row)}
                        >
                          <img
                            src={editActionsIcon}
                            style={{ width: '20px' }}
                            alt="Reset"
                          />
                          <p className="hover-item">Reset</p>
                        </button>
                      ) : (
                        ''
                      )}
                      {row.urlSignature && (
                        <button
                          type="button"
                          className="action"
                          onClick={() =>
                            openSignature(
                              row.urlSignature,
                              row.finishedOrderDate
                            )
                          }
                        >
                          <CameraAlt htmlColor="#0095E8" />
                          <p className="hover-item">Assinatura</p>
                        </button>
                      )}
                    </div>
                  ),
                },
                {
                  title: 'Fluxo',
                  type: 'stringCenter',
                  orderable: true,
                  props: ['isWorkflowDefault'],
                  cssProps: {
                    width: '20%',
                  },
                  renderItem: row => (
                    <div className="default_text_wrapper">
                      <span className="default_text">
                        {row.isWorkflowDefault ? 'Normal' : 'Reduzido'}
                      </span>
                    </div>
                  ),
                },
                {
                  title: 'Sep. Dt. Inicio',
                  type: 'datetime',
                  orderable: true,
                  props: ['startSeparationDate'],
                },
                {
                  title: 'Sep. Dt. Fim',
                  type: 'datetime',
                  orderable: true,
                  props: ['endSeparationDate'],
                },
                {
                  title: 'Conferencia',
                  type: 'datetime',
                  orderable: true,
                  props: ['conferenceDate'],
                },
                {
                  title: 'Data Recebimento',
                  type: 'datetime',
                  orderable: true,
                  props: ['receiptDate'],
                },
                {
                  title: 'Data Entrega',
                  type: 'datetime',
                  orderable: true,
                  props: ['finishedOrderDate'],
                },
                {
                  title: 'Tipo de Entrega',
                  type: 'stringCenter',
                  orderable: true,
                  props: ['clientType'],
                },
              ]}
              rows={rows}
              exportList
              emptyDataMessage="Não foram encontrados pedidos"
              paginationOptions={[
                {
                  value: 30,
                  label: 30,
                },
                {
                  value: 50,
                  label: 50,
                },
                {
                  value: 100,
                  label: 100,
                },
              ]}
            />
          </TableContainer>
        </Container>
      </Fade>
    </>
  );
};

export default OrdersTable;
