/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import React, { useCallback, useRef, useState } from 'react';
import PageMode from '../../../../components/PageMode';
import { useToast } from '../../../../hooks/Toast';
import { ICreateTicketProps } from './types';
import { handleCreateTicket, uploadFile } from '../../api/occurence.api';

import plusIcon from '../../../../assets/svg/plus-icon.svg';
import {
  ContentWrapper,
  FileName,
  FileNamesBox,
  FileUploadButton,
  FileUploadImage,
  FileUploadTitle,
} from './styles';

interface OccurrenceCreateData {
  branchId: string;
  categoryId: string;
  sectorId: string;
  order: string;
  clientName: string;
  documentCpf: string;
  title: string;
  description: string;
}

interface Errors {
  fileError?: string;
  branchIdError?: string;
  categoryIdError?: string;
  sectorIdError?: string;
}

const CreateTicket: React.FC<ICreateTicketProps> = ({
  isOpen,
  handleClose,
  rechargeTicketData,
  stores,
  types,
  areas,
  user,
}) => {
  const [isModalLoading, setIsModalLoading] = useState(false);
  const editInputsRef = useRef<FormHandles>(null);
  const { Toastsuccess, ToastError } = useToast();
  const [file, setFile] = useState(undefined);
  const [errorsMessage, setErrorsMessage] = useState<Errors>();

  const hanldleCreate = useCallback(
    async (data: OccurrenceCreateData) => {
      if (file === undefined) {
        setErrorsMessage({ fileError: 'Arquivo é obrigatório' });
        return;
      }

      if (data.branchId === '') {
        setErrorsMessage({ branchIdError: 'Loja é obrigatória' });
        ToastError({ message: 'Loja é obrigatória' });
        return;
      }

      if (data.categoryId === '') {
        setErrorsMessage({ categoryIdError: 'Tipo é obrigatório' });
        ToastError({ message: 'Tipo é obrigatório' });
        return;
      }

      if (data.sectorId === '') {
        setErrorsMessage({ sectorIdError: 'Área é obrigatória' });
        ToastError({ message: 'Área é obrigatória' });
        return;
      }

      const userData = JSON.parse(user);
      const formData = new FormData();

      formData.append('file', file as unknown as Blob);
      const fileResponse = await uploadFile(formData);
      const fileUrl = fileResponse.data.url;

      handleCreateTicket({
        ...data,
        sectorId: Number(data.sectorId),
        categoryId: Number(data.categoryId),
        createdAt: new Date(),
        createdByUserId: userData.profile.id,
        status: 'Aberto',
        attachment: [
          {
            userId: userData.profile.id,
            createdAt: new Date(),
            urlFile: fileUrl,
          },
        ],
        comment: [
          {
            userId: userData.profile.id,
            newStatus: 'Aberto',
            previousStatus: 'Aberto',
            sectorId: Number(data.sectorId),
            createdAt: new Date(),
            commented: data.description,
          },
        ],
      })
        .then(() => {
          Toastsuccess({
            message: `Ocorrência criada com sucesso!`,
          });
        })
        .catch(err => {
          console.log(err);
          ToastError({ message: `Falha ao criar,ocorrência já existe!` });
          handleClose('edit');
        })
        .finally(() => {
          setFile(undefined);
          rechargeTicketData({
            search: null,
            is_workflow: false,
          });
          handleClose('edit');
          setIsModalLoading(false);
        });
    },
    [ToastError, Toastsuccess, file, handleClose, rechargeTicketData, user]
  );

  const saveImage = React.useCallback(async e => {
    const fileDate = e.target.files[0];
    setFile(fileDate);
  }, []);

  return (
    <PageMode
      isModal
      open={isOpen}
      modalLoading={isModalLoading}
      handleClose={() => handleClose('edit')}
      modalRefObject={editInputsRef}
      submitButtonTitle="Salvar"
      submitButtonIcon
      title="Cadastro Ocorrência"
      handleSubmit={data => hanldleCreate(data as OccurrenceCreateData)}
      inputs={[
        {
          name: 'branchId',
          label: 'Loja',
          placeholder: 'Selecione a loja',
          isRequired: true,
          type: 'select',
          options: stores,
          xl: 4,
          lg: 4,
          xs: 12,
          md: 4,
          sm: 6,
        },
        {
          name: 'categoryId',
          label: 'Tipo',
          placeholder: 'Selecione o tipo',
          isRequired: true,
          type: 'select',
          options: types,
          xl: 4,
          lg: 4,
          xs: 12,
          md: 4,
          sm: 6,
        },
        {
          name: 'sectorId',
          label: 'Área',
          placeholder: 'Selecione a área',
          isRequired: true,
          type: 'select',
          options: areas,
          xl: 4,
          lg: 4,
          xs: 12,
          md: 4,
          sm: 6,
        },
        {
          name: 'order',
          label: 'Pedido',
          placeholder: 'Digite o pedido',
          type: 'text',
          isRequired: true,
          xl: 4,
          lg: 4,
          xs: 12,
          md: 4,
          sm: 6,
        },
        {
          name: 'clientName',
          label: 'Nome',
          placeholder: 'Digite o nome',
          type: 'text',
          isRequired: true,
          xl: 4,
          lg: 4,
          xs: 12,
          md: 4,
          sm: 6,
        },
        {
          name: 'documentCpf',
          label: 'CPF',
          placeholder: 'Digite o CPF',
          type: 'text',
          isRequired: true,
          xl: 4,
          lg: 4,
          xs: 12,
          md: 4,
          sm: 6,
        },
        {
          name: 'title',
          label: 'Título',
          placeholder: 'Digite o título',
          type: 'text',
          isRequired: true,
          xl: 12,
          lg: 12,
          xs: 12,
          md: 12,
          sm: 12,
        },
        {
          name: 'description',
          label: 'Descrição',
          placeholder: 'Digite a descrição',
          type: 'text',
          isRequired: true,
          xl: 12,
          lg: 12,
          xs: 12,
          md: 12,
          sm: 12,
        },
      ]}
    >
      <ContentWrapper>
        <FileUploadTitle>Upload arquivo</FileUploadTitle>
        <input
          type="file"
          multiple
          id="inputFiles"
          style={{ display: 'none' }}
          onChange={saveImage}
        />
        <FileUploadButton htmlFor="inputFiles">
          <FileUploadImage src={plusIcon} alt="Button icon" loading="eager" />
        </FileUploadButton>
        {file !== undefined && (
          // eslint-disable-next-line react/jsx-no-comment-textnodes
          <FileNamesBox>
            {/* @ts-ignore */}
            <FileName>{file.name}</FileName>
          </FileNamesBox>
        )}
        {file === undefined &&
          errorsMessage &&
          errorsMessage.fileError !== undefined && (
            // eslint-disable-next-line react/jsx-no-comment-textnodes
            <FileNamesBox>
              {/* @ts-ignore */}
              <FileName>{errorsMessage.fileError}</FileName>
            </FileNamesBox>
          )}
      </ContentWrapper>
    </PageMode>
  );
};
export default CreateTicket;
