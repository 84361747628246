/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/jsx-curly-newline */
import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useLayoutEffect,
} from 'react';
import { FormHandles } from '@unform/core';
import { Container } from './styles';
import SearchBox from '../../../components/Search';
import { useToast } from '../../../hooks/Toast';
import LoadingComponent from '../../../components/LoadingComponent';
import { IDescriptionStoreProps } from '../../Orders/types';
import {
  getSelectOptions,
  getTicketDetailData,
  handleEditTicket,
  handleSearchTickets,
} from '../api/occurence.api';
import TicketsTable from '../components/TicketsTable';
import { ITicket, ITicketDetailsProps, TicketDetailProps } from '../types';
import PageMode from '../../../components/PageMode';
import TicketDetailsTable from '../components/TicketDetailsTable';
import EditTicket from '../components/EditTicket';
import CreateTicket from '../components/CreateTicket';

const Occurrence: React.FC = () => {
  const searchBoxRef = useRef<FormHandles>(null);
  const editTicketRef = useRef<FormHandles>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState([]);
  const [categories, setCategories] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [subsidiarys, setSubsidiarys] = useState<IDescriptionStoreProps[]>([]);
  const [showTable, setShowTable] = useState(false);
  const [occurrenceData, setOccurrenceData] = useState<any[]>([]);
  const [disabledStore, setDisabledStore] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [currentStatus, setCurrentStatus] = useState();
  const [editTicket, setEditTicket] = useState(false);
  const [ticketDetailInformation, setTicketDetailInformation] =
    useState<ITicketDetailsProps>({} as ITicketDetailsProps);
  const [ticketDetailsData, setTicketDetailsData] = useState<
    TicketDetailProps[]
  >([]);
  const { Toastsuccess, ToastError } = useToast();
  const user = localStorage.getItem('@pdamodules::user');
  const idStore = localStorage.getItem('@pdamodules::storeId');

  const descriptionStore = subsidiarys.filter(
    store => store.value === Number(idStore)
  );
  const storeId = descriptionStore.map(res => res.value);
  const handleStoreInput = useCallback(() => {
    if (idStore !== null) {
      setDisabledStore(true);
      searchBoxRef.current?.setFieldValue('loja', {
        value: String(storeId),
        label: descriptionStore.map(res => res.label),
      });
    }
  }, [descriptionStore, idStore, storeId]);

  const tzoffset = new Date().getTimezoneOffset() * 60000;
  const dateDay = new Date(Date.now() - tzoffset).toISOString().split('T')[0];

  const getAllOptions = useCallback(async () => {
    const [statusRes, lojasRes, categoriesRes, sectorsRes] =
      await getSelectOptions();

    setStatus(statusRes);
    setSubsidiarys(lojasRes);
    setCategories(categoriesRes);
    setSectors(sectorsRes);

    console.log(lojasRes);
    console.log(categoriesRes);
  }, []);

  const handleSubmit = useCallback(
    async formData => {
      setIsLoading(true);
      setShowTable(false);
      await handleSearchTickets(formData)
        .then(res => {
          console.log(res.data);
          console.log(res.data);
          if (!res.data.length) {
            setOccurrenceData([]);
            ToastError({ message: 'Não foram encontrado ocorrência(s)!' });
          } else {
            setOccurrenceData(res.data);
            Toastsuccess({
              message: `${res.data.length} ocorrência(s) encontrados!`,
            });
            setShowTable(true);
          }
        })
        .catch(err => {
          ToastError({ message: err });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [ToastError, Toastsuccess]
  );

  const handleDetail = async (row: ITicket) => {
    try {
      setModalLoading(true);
      setOpen(true);

      const response = await getTicketDetailData(row.id);
      console.log(response.data[0]);

      if (!response.data.length) {
        ToastError({ message: 'Resultados não encontrados' });
        return;
      }

      const sortedComments = response.data[0].ticketComments
        .slice()
        .sort(
          (a: any, b: any) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );

      setTicketDetailInformation({
        ...response.data[0],
        ticketComments: sortedComments,
      });
    } catch (error) {
      ToastError({ message: 'Erro ao buscar detalhes do ticket' });
      console.error(error);
    } finally {
      setModalLoading(false);
    }
  };

  const setInitialFields = useCallback(() => {
    searchBoxRef.current?.setFieldValue('initialDate', dateDay);
    searchBoxRef.current?.setFieldValue('finalDate', dateDay);
    handleSubmit({
      initialDate: dateDay,
      finalDate: dateDay,
      loja: idStore,
    });
  }, [dateDay, handleSubmit, idStore]);

  const openEditTicket = () => {
    setEditTicket(true);
  };

  const handleSubmitEdit = useCallback(
    async data => {
      // @ts-ignore
      const userData = JSON.parse(user);

      const adjustedDate = new Date();
      adjustedDate.setHours(adjustedDate.getHours() - 3);

      if (!data.description) {
        ToastError({ message: 'Preencha todos os campos!' });
        return;
      }

      try {
        const ticketEditData = {
          id: ticketDetailInformation.id,
          // @ts-ignore
          status: status.find((i: any) => i.value === data.status)?.label,
          sectorId: data.sectorId
            ? Number(data.sectorId)
            : ticketDetailInformation.sectorId,
          comment: [
            {
              userId: userData.profile.id,
              newStatus:
                // @ts-ignore
                status.find((i: any) => i.value === data.status)?.label,
              previousStatus: status.find(
                (i: any) => i.value === ticketDetailInformation.status
                // @ts-ignore
              )?.label,
              sectorId: data.sectorId
                ? Number(data.sectorId)
                : ticketDetailInformation.sectorId,
              createdAt: adjustedDate,
              reason: data.reason,
              commented: data.description,
            },
          ],
        };

        await handleEditTicket(ticketEditData)
          .then(response => {
            if (response.status === 200) {
              Toastsuccess({ message: 'Comentário criado com sucesso!' });
              setTicketDetailInformation(previousState => ({
                ...previousState,
                // @ts-ignore
                status: status.find((i: any) => i.value === data.status)?.label,
                ticketComments: [
                  ...previousState.ticketComments,
                  {
                    commented: data.description,
                    userId: userData.profile.id,
                    createdByUserName: userData.name,
                    createdAt: adjustedDate.toISOString(),
                    user: {
                      first_name: userData.name,
                      email: '',
                    },
                  },
                ],
              }));
              setEditTicket(false);
              const formData = searchBoxRef.current?.getData();

              handleSubmit(formData);
            }
            console.log('Resposta da requisição:', response);
          })
          .catch(error => {
            console.error('Erro na requisição:', error.message);
          });

        console.log('Formulário enviado com sucesso:', ticketEditData);
      } catch (error) {
        ToastError({ message: 'Erro ao criar ocorrência.' });
        console.error(
          'Erro ao processar a imagem ou enviar o formulário:',
          error
        );
      }
    },
    [
      ToastError,
      Toastsuccess,
      handleSubmit,
      status,
      ticketDetailInformation.id,
      ticketDetailInformation.sectorId,
      ticketDetailInformation.status,
      user,
    ]
  );

  useLayoutEffect(() => {
    getAllOptions();
  }, [getAllOptions, user]);

  useEffect(() => {
    setInitialFields();
  }, [setInitialFields]);

  useEffect(() => {
    const defaultStatus = status.find(
      (i: any) => i.label === ticketDetailInformation.status
    );
    const defaultSector = sectors.find(
      (i: any) => i.label === ticketDetailInformation.sectorName
    );
    editTicketRef.current?.setFieldValue('status', defaultStatus);
    editTicketRef.current?.setFieldValue('sectorId', defaultSector);
  }, [
    editTicket,
    sectors,
    status,
    ticketDetailInformation.sectorName,
    ticketDetailInformation.status,
  ]);

  useEffect(() => {
    if (descriptionStore.length) {
      handleStoreInput();
    }
  }, [handleStoreInput, descriptionStore]);

  const hanldleCloseModal = useCallback(() => {
    setOpenCreateModal(false);
  }, []);

  return (
    <>
      <Container withOutPadding>
        <CreateTicket
          isOpen={openCreateModal}
          rechargeTicketData={handleSubmit}
          handleClose={hanldleCloseModal}
          stores={subsidiarys}
          types={categories}
          areas={sectors}
          user={user}
        />
        <SearchBox
          handleSubmit={data => handleSubmit(data)}
          createButton={() => setOpenCreateModal(true)}
          searchBoxRef={searchBoxRef}
          searchDisabled={isLoading}
          title="Consulta de Ocorrência"
          inputs={[
            {
              name: 'order',
              label: 'Pedido',
              placeholder: 'Digite o pedido',
              type: 'text',
              xl: 2,
              lg: 2,
              md: 12,
              sm: 12,
              xs: 12,
            },
            {
              name: 'clientName',
              label: 'Cliente',
              placeholder: 'Digite o nome do cliente',
              type: 'text',
              xl: 2,
              lg: 2,
              md: 12,
              sm: 12,
              xs: 12,
            },
            {
              name: 'documentCpf',
              label: 'CPF',
              placeholder: 'Digite o CPF',
              type: 'text',
              xl: 2,
              lg: 2,
              md: 12,
              sm: 12,
              xs: 12,
            },
            {
              name: 'loja',
              label: 'Loja',
              placeholder: 'Selecione a Loja',
              type: 'select',
              options: descriptionStore.length ? descriptionStore : subsidiarys,
              isDisabled: disabledStore,
              xl: 3,
              lg: 3,
              md: 12,
              sm: 12,
              xs: 12,
            },
            {
              name: 'status',
              label: 'Status',
              placeholder: 'Selecione o Status',
              type: 'select',
              options: status,
              xl: 3,
              lg: 3,
              md: 12,
              sm: 12,
              xs: 12,
            },
            {
              name: 'initialDate',
              label: 'Data Inicial:',
              type: 'date',
              defaultValue: dateDay,
              xl: 2,
              lg: 2,
              md: 12,
              sm: 12,
            },
            {
              name: 'finalDate',
              label: 'Data Final:',
              type: 'date',
              defaultValue: dateDay,
              xl: 2,
              lg: 2,
              md: 12,
              sm: 12,
              xs: 12,
            },
            {
              name: 'categoryId',
              label: 'Tipo',
              placeholder: 'Selecione o tipo',
              type: 'select',
              options: categories,
              xl: 3,
              lg: 3,
              md: 12,
              sm: 12,
              xs: 12,
            },
            {
              name: 'sectorId',
              label: 'Área',
              placeholder: 'Selecione a área',
              type: 'select',
              options: sectors,
              xl: 3,
              lg: 3,
              md: 12,
              sm: 12,
              xs: 12,
            },
          ]}
          createButtonContent={
            <>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.7692 14.7692H2.25641C1.91672 14.7692 1.64103 15.0449 1.64103 15.3846C1.64103 15.7243 1.91672 16 2.25641 16H15.1795C15.6144 16 16 15.6144 16 15.1795V2.25641C16 1.91672 15.7243 1.64103 15.3846 1.64103C15.0449 1.64103 14.7692 1.91672 14.7692 2.25641V14.7692ZM13.9487 0.820513C13.9487 0.428308 13.6377 0 13.1282 0H0.820513C0.311795 0 0 0.425846 0 0.820513V13.1282C0 13.6377 0.426667 13.9487 0.820513 13.9487H13.1282C13.5204 13.9487 13.9487 13.6377 13.9487 13.1282V0.820513ZM1.23077 1.23077H12.7179V12.7179H1.23077V1.23077ZM6.35897 6.35897H3.89744C3.55774 6.35897 3.28205 6.63467 3.28205 6.97436C3.28205 7.31405 3.55774 7.58974 3.89744 7.58974H6.35897V10.0513C6.35897 10.391 6.63467 10.6667 6.97436 10.6667C7.31405 10.6667 7.58974 10.391 7.58974 10.0513V7.58974H10.0513C10.391 7.58974 10.6667 7.31405 10.6667 6.97436C10.6667 6.63467 10.391 6.35897 10.0513 6.35897H7.58974V3.89744C7.58974 3.55774 7.31405 3.28205 6.97436 3.28205C6.63467 3.28205 6.35897 3.55774 6.35897 3.89744V6.35897Z"
                  fill="white"
                />
              </svg>
              <p>Nova Ocorrência</p>
            </>
          }
        />
      </Container>
      {isLoading && <LoadingComponent />}
      {showTable && (
        <TicketsTable
          handleFilter={handleSubmit}
          ticketTableRows={occurrenceData}
          openDetail={handleDetail}
        />
      )}
      <PageMode
        isModal
        modalLoading={modalLoading}
        handleSubmit={openEditTicket}
        open={open}
        submitButtonTitle="Comentário"
        handleClose={() => setOpen(!open)}
        title="Detalhes"
      >
        <TicketDetailsTable
          statusOptions={status}
          ticket={ticketDetailInformation}
        />
      </PageMode>
      <PageMode
        isModal
        modalRefObject={editTicketRef}
        modalLoading={modalLoading}
        open={editTicket}
        handleSubmit={data => handleSubmitEdit(data)}
        submitButtonIcon
        submitButtonTitle="Salvar"
        handleClose={() => setEditTicket(!editTicket)}
        title="Adicionar comentário"
        inputs={[
          {
            name: 'sectorId',
            label: 'Área',
            placeholder: 'Selecione a área',
            type: 'select',
            options: sectors,
            xl: 4,
            lg: 4,
            md: 12,
            sm: 12,
            xs: 12,
          },
          {
            name: 'status',
            label: 'Status',
            placeholder: 'Selecione o Status',
            type: 'select',
            options: status,
            onChangeEvent(value) {
              setCurrentStatus(value);
            },
            xl: 4,
            lg: 4,
            md: 12,
            sm: 12,
            xs: 12,
          },
          {
            name: 'reason',
            label: 'Motivo',
            placeholder: 'Selecione o Motivo',
            type: 'select',
            options: [
              { label: 'Estorno PIX efetuado', value: 'Estorno PIX efetuado' },
              {
                label: 'Estorno cartão CAEDU efetuado',
                value: 'Estorno cartão CAEDU efetuado',
              },
              {
                label: 'Devolutiva de status para o cliente',
                value: 'Devolutiva de status para o cliente',
              },
              { label: 'Devolutiva para loja', value: 'Devolutiva para loja' },
            ],
            isDisabled: currentStatus !== 'Fechado',
            xl: 4,
            lg: 4,
            md: 12,
            sm: 12,
            xs: 12,
          },
          {
            name: 'description',
            label: 'Mensagem',
            isRequired: true,
            type: 'textarea',
            xl: 12,
            lg: 12,
            xs: 12,
            md: 6,
            sm: 6,
          },
        ]}
      >
        <EditTicket />
      </PageMode>
    </>
  );
};
export default Occurrence;
